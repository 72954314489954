import * as THREE from 'three';
import React, { Suspense, useEffect, useRef, useState, useMemo } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import {
  useGLTF,
  useAnimations,
  Reflector,
  MeshReflectorMaterial,
  useTexture,
  OrbitControls,
  CameraShake,
  Environment
} from '@react-three/drei';
import useStore from './store';
import { useControls, folder } from 'leva';
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import {
  EffectComposer,
  DepthOfField,
  Bloom,
  Noise,
  Vignette
} from '@react-three/postprocessing';

const HPI = Math.PI / 2;
const vec = new THREE.Vector3();
const obj = new THREE.Object3D();
const red = new THREE.Color('#ff0000');

export default function App(props) {
  const index = useStore(state => state.index);
  const audios = useStore(state => state.audios);

  console.log('index', index);

  const controlsRef = useRef();

  // const { rotation } = useControls({
  //   rotation: [-Math.PI / 2, 0, 0]
  // });

  // const { position } = useControls({
  //   position: [0, -0.55, 0]
  // });

  return (
    <>
      <Canvas dpr={[1, 2]}>
        <color attach='background' args={['#d0d0d0']} />

        <mesh position={[0, -0.5, 0]} rotation={[-Math.PI / 2, 0, 0]}>
          <planeGeometry args={[50, 50]} />
          <MeshReflectorMaterial
            blur={[400, 100]}
            resolution={1024}
            mixBlur={1}
            mixStrength={15}
            depthScale={1}
            minDepthThreshold={0.85}
            color='#151515'
            metalness={0.6}
            roughness={1}
          />
        </mesh>

        <Environment preset='studio' />

        <fog attach='fog' args={['#d0d0d0', 5, 10]} />

        <ambientLight intensity={1} color='#ffffff' />

        <OrbitControls ref={controlsRef} makeDefault />

        <directionalLight position={[5, 10, -5]} intensity={1} />

        <directionalLight position={[0, -3, -10]} intensity={1} />

        <Suspense fallback={null}>
          <group position-y={-0.25}>
            <Graph
              rotation={[-0.1, Math.PI / 3, 0]}
              position={[1, 0.1, 1]}
              audio={index}
            />

            <Graph
              rotation={[0.1, Math.PI / 3, 0]}
              position={[-3, 0.1, 0]}
              audio={index}
            />

            <Graph
              rotation={[0.1, Math.PI, 0]}
              position={[0, 0.1, 1]}
              audio={index}
            />

            {/* {audios.map((_audio, i) => (
              <Graph
                key={i}
                position={[-0.7, -0.2 + i * 0.5, -i - 1]}
                audio={i}
                
              />
            ))} */}

            {/* <mesh
              receiveShadow
              position={[0, -0.3, 0]}
              rotation={[-Math.PI / 2, 0, 0]}
              scale={10}
            >
              <planeBufferGeometry />
              <shadowMaterial />
            </mesh> */}
          </group>

          <CamPos />
          <group>
            <Model rotation={[0, Math.PI, 0]} position={[0, 0.51, 0]} />

            <Model rotation={[0, Math.PI / 3, 0]} position={[-1, 0.51, 0.5]} />

            <Model
              rotation={[0, -Math.PI / 3, 0]}
              position={[-1, 0.51, -0.5]}
            />

            {/* <mesh
              scale={[100, 100, 100]}
              rotation={[-Math.PI / 2, 0, 0]}
              position={[0, -0.551, 0]}
            >
              <planeGeometry />
              <MeshReflectorMaterial
                color={'#d0d0d0'}
                resolution={64}
                // blur={[0, 0]} // Blur ground reflections (width, heigt), 0 skips blur
                mixBlur={0} // How much blur mixes with surface roughness (default = 1)
                mixStrength={1} // Strength of the reflections
                mixContrast={1} // Contrast of the reflections
                // resolution={256} // Off-buffer resolution, lower=faster, higher=better quality, slower
                mirror={0} // Mirror environment, 0 = texture colors, 1 = pick up env colors
                depthScale={0} // Scale the depth factor (0 = no depth, default = 0)
                minDepthThreshold={0.9} // Lower edge for the depthTexture interpolation (default = 0)
                maxDepthThreshold={1} // Upper edge for the depthTexture interpolation (default = 0)
                // depthToBlurRatioBias={0.25} // Adds a bias factor to the depthTexture before calculating the blur amount [blurFactor = blurTexture * (depthTexture + bias)]. It accepts values between 0 and 1, default is 0.25. An amount > 0 of bias makes sure that the blurTexture is not too sharp because of the multiplication with the depthTexture
                // distortion={1} // Amount of distortion based on the distortionMap texture
                // distortionMap={distortionTexture} // The red channel of this texture is used as the distortion map. Default is null
                // reflectorOffset={0.2} // Offsets the virtual camera that projects the reflection. Useful when the reflective surface is some distance from the object's origin (default = 0)
              />
            </mesh> */}
          </group>
        </Suspense>

        <EffectComposer>
          <Noise opacity={0.02} />
          <Vignette eskil={false} offset={0.1} darkness={0.9} />
        </EffectComposer>
      </Canvas>
      <Buttons />
    </>
  );
}

// function Phone({ rotation }) {
//   const gltf = useLoader(GLTFLoader, '/phone/phone.glb');

//   const map = useLoader(TextureLoader, '/phone/phone.jpg');

//   console.log(gltf);

//   return (
//     <Suspense fallback={null}>
//       <group scale={[0.5, 0.5, 0.5]} rotation={rotation}>
//         <primitive object={gltf.scene} />

//         <mesh
//           scale={[1.9, 3.8, 1]}
//           rotation={[0, -Math.PI / 2, 0]}
//           position={[-0.18, 0, 0]}
//         >
//           <planeBufferGeometry />
//           <meshBasicMaterial map={map} />
//         </mesh>
//       </group>
//     </Suspense>
//   );
// }

export function Model(props) {
  const { nodes, materials } = useGLTF('/phone/phone.glb');

  materials['Material.001'].side = 1;
  materials['Material.003'].side = 1;
  materials['Material.004'].side = 1;

  return (
    <group {...props} dispose={null} scale={[0.5, 0.5, 0.5]}>
      <group rotation={[0, 0, -Math.PI / 2]} scale={[2.09, 1, 1]}>
        <mesh
          geometry={nodes.Plane_1.geometry}
          material={materials['Material.003']}
        />
        <mesh
          geometry={nodes.Plane_2.geometry}
          material={materials['Material.001']}
        />
        <mesh
          geometry={nodes.Plane_3.geometry}
          material={materials['Material.004']}
        />
      </group>
    </group>
  );
}

useGLTF.preload('/phone.glb');

function Graph(props) {
  const audios = useStore(state => state.audios);

  const ref = useRef();
  useFrame(() => {
    for (let i = 0; i < 64; i++) {
      obj.position.set(i * 0.04, audios[props.audio].data[i] / 1000, 0);
      obj.updateMatrix();
      ref.current.setMatrixAt(i, obj.matrix);
    }
    ref.current.instanceMatrix.needsUpdate = true;
  });
  return (
    <instancedMesh ref={ref} args={[null, null, 64]} {...props}>
      <planeGeometry args={[0.02, 0.1]} />

      <meshBasicMaterial
        color={red}
        side={2}
        // toneMapped={false}
        // transparent
        // opacity={0.25}
      />
    </instancedMesh>
  );
}

function Buttons() {
  const setIndex = useStore(state => state.setIndex);
  const api = useStore(state => state.api);

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        textAlign: 'center',
        pointerEvents: 'none'
      }}
    >
      {[0, 1, 2].map(index => (
        <button
          style={{
            width: 50,
            height: 50,
            pointerEvents: 'all',
            fontWeight: 'bold',
            margin: 8
          }}
          key={index}
          onClick={() => {
            setIndex(index);

            api.start(index);
          }}
        >
          {index}
        </button>
      ))}
    </div>
  );
}

const cameraTarget = new THREE.Vector3(0, 0, 0);
const dummy = new THREE.Vector3(0, 0, 0);

function CamPos() {
  const clicked = useStore(state => state.clicked);
  const api = useStore(state => state.api);
  useEffect(() => {
    api.loaded();
  }, []);

  const index = useStore(state => state.index);

  const camPosition = useStore(state => state.camPositions)[index];
  const camTarget = useStore(state => state.camTargets)[index];

  // Zoom in camera when user has pressed start
  return useFrame(state => {
    if (clicked) {
      state.camera.position.lerp(vec.set(...camPosition), 0.05);

      dummy.lerp(cameraTarget.set(...camTarget), 0.025);

      const elapsedTime = state.clock.getElapsedTime();

      const shakeFactor = 0.25;
      const shakeSpeedFactor = 1.2;

      dummy.x +=
        Math.sin(shakeSpeedFactor * elapsedTime * 0.936735) *
        0.008476454 *
        Math.cos(shakeSpeedFactor * elapsedTime * 0.936735) *
        shakeFactor;
      dummy.y +=
        Math.sin(shakeSpeedFactor * elapsedTime * 1.02736) *
        0.00347354 *
        Math.cos(shakeSpeedFactor * elapsedTime * 1.02736) *
        shakeFactor;
      dummy.z +=
        Math.sin(shakeSpeedFactor * elapsedTime * 1.18267) *
        0.00767483 *
        Math.cos(shakeSpeedFactor * elapsedTime * 1.18267) *
        shakeFactor;

      state.camera.lookAt(dummy);
    }
  });
}
