import { createRoot } from 'react-dom/client';
import React from 'react';
import App from './App';
import useStore from './store';
import './styles.css';

function Overlay() {
  const loaded = useStore(state => state.loaded);
  const clicked = useStore(state => state.clicked);
  const api = useStore(state => state.api);

  const index = useStore(state => state.index);

  return (
    <>
      <div
        className={`fullscreen bg ${loaded ? 'loaded' : 'notready'} ${
          clicked && 'clicked'
        }`}
      >
        <div onClick={() => loaded && api.start(0)}>
          {!loaded ? (
            'loading'
          ) : (
            <>
              <span style={{ color: '#606060' }}>Done loading :)</span>
              <br />
              <span style={{ color: '#606060' }}>
                This experience has audio!
              </span>
              <br />
              <b>
                <span style={{ color: 'black' }}>Click to continue</span>
              </b>
            </>
          )}
        </div>
      </div>
    </>
  );
}

createRoot(document.querySelector('#root')).render(
  <>
    <App />
    <Overlay />
  </>
);
